<template>
  <CHeader fixed with-subheader light>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <h3>HNC DBMS</h3>
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/files/active">
          Active Files
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/files/dormant">
          Dormant Files
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/files/sendtoclient">
          Send To Client
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/files/closed">
          Closed
        </CHeaderNavLink>
      </CHeaderNavItem>
    </CHeaderNav>
    <CHeaderNav>
      <CForm class="mr-5" @submit.prevent="submit_search(search_term)" inline>
        <CInput
          class="mr-2 my-0"
          v-model="search_term"
          required="required"
          placeholder="Search Files"
          size="sm"
        />
        <CButton
          :disabled="search_term.length === 0"
          @click="submit_search(search_term)"
          color="dark"
          size="sm"
        >
          Search
        </CButton>
      </CForm>
    </CHeaderNav>
    <CHeaderNav class="mr-4">
      <!-- <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-envelope-open" />
          <CBadge color="primary" class="position-static">4</CBadge>
        </CHeaderNavLink>
      </CHeaderNavItem> -->
      <!-- <TheHeaderDropdownNotifs /> -->
      <TheHeaderDropdownAccnt />
    </CHeaderNav>
    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0" />
    </CSubheader>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from "./TheHeaderDropdownAccnt";
import TheHeaderDropdownNotifs from "./TheHeaderDropdownNotifs";

export default {
  name: "TheHeader",
  components: {
    TheHeaderDropdownAccnt,
    TheHeaderDropdownNotifs,
  },
  data() {
    return {
      search_term: "",
    };
  },
  methods: {
    submit_search(search_term) {
      let needle = search_term.replace(/\//gi, "_");
      // needle = encodeURIComponent(search_term);
      console.log(needle);
      // var vm = this;
      console.log(search_term);

      this.$router.push(`/files/search/${needle}`);
    },
  },
};
</script>
