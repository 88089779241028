// import axios from "axios";

export default [
  {
    _name: "CSidebarNav",
    _children: [
      {
        _name: "CSidebarNavTitle",
        _children: ["Dashboard"],
      },
      {
        _name: "CSidebarNavItem",
        name: "Dashboard",
        to: "/dashboard",
        icon: "cil-calculator",
      },
      {
        _name: "CSidebarNavTitle",
        _children: ["Bring Ups"],
      },
      {
        _name: "CSidebarNavItem",
        name: "Overdue",
        to: "/bringups/overdue",
        icon: "cil-star",
      },
      {
        _name: "CSidebarNavItem",
        name: "Today/Tomorrow",
        to: "/bringups/today",
        icon: "cil-star",
      },
      {
        _name: "CSidebarNavItem",
        name: "This Week",
        to: "/bringups/week",
        icon: "cil-star",
      },
      {
        _name: "CSidebarNavItem",
        name: "This Month",
        to: "/bringups/month",
        icon: "cil-star",
      },
      {
        _name: "CSidebarNavItem",
        name: "This Quarter",
        to: "/bringups/quarter",
        icon: "cil-star",
      },
      {
        _name: "CSidebarNavTitle",
        _children: ["Files"],
      },
      {
        _name: "CSidebarNavItem",
        name: "Active Files",
        to: "/files/active",
        icon: "cil-file",
      },
      {
        _name: "CSidebarNavItem",
        name: "Dormant Files",
        to: "/files/dormant",
        icon: "cil-envelope-closed",
      },
      {
        _name: "CSidebarNavItem",
        name: "Send To Client",
        to: "/files/sendtoclient",
        icon: "cil-user",
      },
      {
        _name: "CSidebarNavItem",
        name: "Closed",
        to: "/files/closed",
        icon: "cil-star",
      },

      {
        _name: "CSidebarNavTitle",
        _children: ["Settings"],
      },
      {
        _name: "CSidebarNavItem",
        name: "Profile",
        to: "/profile",
        icon: "cil-settings",
        // icon: "cil-book"
      },
      {
        _name: "CSidebarNavItem",
        name: "Users",
        to: "/users",
        icon: "cil-settings",
        // icon: "cil-book"
      },
      // {
      //   _name: "CSidebarNavItem",
      //   name: "Datasets",
      //   to: "/datasets",
      //   icon: "cil-settings",
      //   // icon: "cil-book"
      // },
    ],
  },
];
