<template>
  <CDropdown :toggler-text="user.initials" class="m-2" color="light">
    <!-- <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>Account</strong>
    </CDropdownHeader> -->
    <!-- <CDropdownItem>
      <CIcon name="cil-bell" /> Updates
      <CBadge color="info" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-envelope-open" /> Messages
      <CBadge color="success" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-task" /> Tasks
      <CBadge color="danger" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem> -->

    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>Settings</strong>
    </CDropdownHeader>
    <CDropdownItem to="/profile">
      <CIcon name="cil-user" /> Profile
    </CDropdownItem>

    <CDropdownDivider />

    <CDropdownItem @click="logout_submit">
      <CIcon name="cil-lock-locked" /> Logout
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "TheHeaderDropdownAccnt",
  data() {
    return {
      itemsCount: 42,
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  methods: {
    logout_submit() {
      const axios = require("axios");
      const user = JSON.parse(localStorage.getItem("user"));
      const token = localStorage.getItem("token");

      localStorage.removeItem("user");
      localStorage.removeItem("token");

      axios
        .post("/logout", user)
        .then((res) => {
          console.log(res);
          // localStorage.removeItem("user");
          // localStorage.removeItem("token");
          // this.$router.push("/a/login");
          window.location.replace("/a/login");
          // window.location.
        })
        .catch((err) => {
          console.log(err);
          // this.$router.push("/a/login");
          window.location.replace("/a/login");

          // Swal.fire("Oops...", err.message, "error");
        });
    },
  },
};
</script>

<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
</style>
