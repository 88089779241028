<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink class="">
        <CIcon name="cil-list" />
        <CBadge
          style="margin-left: -1.4vh;"
          color="warning"
          class="position-static"
          >{{ past_cases.length }}</CBadge
        >
      </CHeaderNavLink>
    </template>

    <CDropdownHeader tag="div" class=" text-center" color="warning">
      <strong style="color: #ffffff">Last Month Bringups</strong>
    </CDropdownHeader>
    <span>
      <CDropdownItem v-for="c in past_cases" :key="c.id">
        <CButton :to="`/case/details/${c.id}`">
          Client: {{ c.client_name }}; Pri: {{ c.bring_up_date_pri }}; Sec:
          {{ c.bring_up_date_sec }};
        </CButton>
      </CDropdownItem>
    </span>
  </CDropdown>
</template>

<script>
import axios from "axios";
export default {
  name: "TheHeaderDropdownNotifs",
  data() {
    return {
      user: {},
      past_cases: [],
      date_options: { year: "numeric", month: "long", day: "numeric" },
    };
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.fetch_past_month_bringups();
  },
  methods: {
    fetch_past_month_bringups() {
      let vm = this;

      axios
        .post("/bringups/past", {
          initials: vm.user.initials,
        })
        .then(function(response) {
          console.log(response.data);
          vm.past_cases = response.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
</style>
